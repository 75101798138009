import { useAtom, useAtomValue, useSetAtom } from "jotai";
import { useEffect, useMemo, useState } from "react";
import { HiOutlinePhoneOutgoing } from "react-icons/hi";
import endCallOutlinePng from "../../assets/img/end-call-outline.png";
import endCallPng from "../../assets/img/end-call.png";
import outletState from "../../states/OutletState";
import { selectedUserInfo } from "../../states/SelectedUserState";
import { useHangup } from "../../utils/hooks/useStartCall";
import styles from "./Call.module.scss";
import * as Voximplant from "voximplant-websdk";
import { callInfo } from "../../states/CallState";
import { callConnectedInfo } from "../../states/callConnectedState";

export default function CallComponent() {
  const selectedUser = useAtomValue(selectedUserInfo);
  const call = useAtomValue(callInfo);
  const isCallConnected = useAtomValue(callConnectedInfo);

  const hangup = useHangup();

  const [isPressed, setIsPressed] = useState(false);

  const setOutletParams = useSetAtom(outletState);

  const [title, setTitle] = useState("Chamando");
  const [callTime, setCallTime] = useState(0);

  useEffect(() => {
    if (call && isCallConnected) {
      const minCount = Math.floor(callTime / 60);
      const min = minCount > 9 ? minCount : "0" + minCount;
      const secCount = callTime > 59 ? callTime % 60 : callTime;
      const sec = secCount > 9 ? secCount : "0" + secCount;

      setTitle(`${min}:${sec}`);
      setTimeout(() => setCallTime(callTime + 1), 1000);
    } else
      setTimeout(() => {
        if (title.length === 11) setTitle("Chamando");
        else setTitle(title + ".");
      }, 500);
  }, [title, isCallConnected, callTime]);

  useEffect(() => setOutletParams({ top: null, bottom: null, title }), [title]);

  return (
    <div className={styles.container}>
      <div className={styles.animation}>
        <div className={styles["animation-out"]}>
          <div className={styles["animation-in"]} />
        </div>
        <div className={styles["animation-icon"]}>
          <HiOutlinePhoneOutgoing />
        </div>
      </div>
      <div className={styles.infos}>
        {selectedUser && (
          <p className={styles["infos-name"]}>{selectedUser.name}</p>
        )}
        <div className={styles["infos-items"]}>
          {selectedUser?.tower && (
            <div className={styles["infos-item"]}>
              <p className={styles["infos-item-label"]}>Torre</p>
              <p className={styles["infos-item-value"]}>{selectedUser.tower}</p>
            </div>
          )}
          {selectedUser?.apartment && (
            <div className={styles["infos-item"]}>
              <p className={styles["infos-item-label"]}>Apartamento</p>
              <p className={styles["infos-item-value"]}>
                {selectedUser.apartment}
              </p>
            </div>
          )}
        </div>
      </div>
      <div className={styles["end-call"]}>
        <button
          onClick={() => hangup()}
          onMouseDown={() => setIsPressed(true)}
          onMouseUp={() => {
            setTimeout(() => {
              setIsPressed(false);
            }, 200);
          }}
          className={styles["end-call-button"]}
        >
          <img src={isPressed ? endCallOutlinePng : endCallPng} alt="" />
        </button>
      </div>
    </div>
  );
}
